<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Nombre</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <SelectInputSimpleState v-model="state.status" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('save')"
                                v-if="state.id"
                            >
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new')"
                            >
                                <i class="far fa-save"></i>
                                Crear
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new_view')"
                            >
                                <i class="far fa-save"></i>
                                Crear y mostrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { reactive, onMounted, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useStore } from 'vuex';
// Components
import { useSnackbar } from 'vue3-snackbar';
import WindowLayout from '@/layouts/Window.layout.vue';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';

// Services
import useInstituciones from './services/useInstituciones';

export default {
    name: 'InstitucionForm',
    components: {
        WindowLayout,
        SelectInputSimpleState,
    },
    props: {
        title: { type: String, default: 'Institucion' },
        headerTitle: { type: String, default: 'Ingresar Institucion' },
        item: Object,
    },
    setup(props) {
        const store = useStore();
        const snackbar = useSnackbar();
        const state = reactive({
            id: '',
            consecutive: '',
            name: '',
            status: 1,
        });

        // eslint-disable-next-line object-curly-newline
        const {
            institucion,
            getInstituciones,
            getInstitucion,
            createInstitucion,
            updateInstitucion,
        } = useInstituciones();

        /** ***** Autocompleters ***** */

        /** ***** Helpers ***** */
        const setState = () => {
            state.id = institucion.value.id;
            state.consecutive = institucion.value.consecutive;
            state.name = institucion.value.name;
            state.status = institucion.value.status;
        };
        const resetWindow = () => {
            state.id = '';
            state.consecutive = '';
            state.name = '';
            state.status = 1;

            institucion.value = null;
        };

        /** ***** Lifecycle Hooks ***** */
        onMounted(async () => {
            if (props.item) {
                await Promise.all([getInstitucion(props.item.id)]);
                if (institucion.value) {
                    setState();
                }
            }
        });

        /** ***** Validator ***** */
        const rules = computed(() => ({
            name: { required },
            status: { required },
        }));
        const $v = useVuelidate(rules, state);

        const handleAction = async (action) => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                // No errors
                let text;
                switch (action) {
                    case 'new':
                    case 'new_view':
                        await createInstitucion({
                            name: state.name,
                            status: state.status,
                        });
                        if (action === 'new_view') {
                            store.dispatch('ContentManager/openWindow', {
                                id: `InstitucionForm_${institucion.value.id}`,
                                component: 'InstitucionForm',
                                name: 'Institución',
                                params: {
                                    title: 'Institución',
                                    headerTitle: 'Editar Institución',
                                    item: { ...institucion.value },
                                },
                            });
                        }
                        resetWindow();
                        text = 'Institucion ingresado';
                        break;
                    case 'save':
                        await updateInstitucion(state.id, {
                            name: state.name,
                            status: state.status,
                        });
                        text = 'Institucion actualizado';
                        break;
                    default:
                        break;
                }
                snackbar.add({
                    type: 'success',
                    text,
                });
                setState();
            } else {
                console.log($v.value.$errors);
            }
        };

        return {
            state,
            getInstituciones,
            /** Actions */
            handleAction,
        };
    },
};
</script>

<style></style>
